import { Content } from '@prismicio/client';
import {
  JSXMapSerializer,
  PrismicRichText,
  SliceComponentProps,
} from '@prismicio/react';

import Container from '@/components/Unknown/Container';
import { PrismicNextImage } from '@prismicio/next';
import { PrismicNextLink } from '@/components/Internal/Button';
import { ArrowRightV2 } from '@/components/Unknown/Icons';

/**
 * Props for `FeaturedLogos`.
 */
export type FeaturedLogosProps =
  SliceComponentProps<Content.FeaturedLogosSlice>;

/**
 * Component for "FeaturedLogos" Slices.
 */

const component: JSXMapSerializer = {
  heading2: ({ children }) => (
    <h2 className="mx-auto text-pretty text-center text-arc-4xl font-bold ~md:~max-w-[42.5rem]/[54.375rem] sm:~lg:~text-arc-7xl/arc-9xl">
      {children}
    </h2>
  ),
  heading3: ({ children }) => (
    <h3 className="text-balance text-center text-arc-4xl font-bold md:~lg:~text-arc-5xl/arc-6xl">
      {children}
    </h3>
  ),
  paragraph: ({ children }) => (
    <p className="mx-auto max-w-prose text-pretty text-center font-normal ~lg:~text-arc-base/arc-2xl">
      {children}
    </p>
  ),
};

const FeaturedLogos = ({ slice }: FeaturedLogosProps): JSX.Element => {
  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className={`text-black ${slice.variation === 'compact' ? 'slice-compact-padding bg-night-blue' : 'slice-base-padding'}`}
    >
      <Container className="text-center">
        <div className="px-0 o-6 empty:hidden md:px-16">
          {slice.primary.show_heading && (
            <PrismicRichText
              field={slice.primary.heading}
              components={component}
            />
          )}

          {slice.primary.show_sub_heading && (
            <PrismicRichText
              field={slice.primary.sub_heading}
              components={component}
            />
          )}
        </div>
        <ul
          className={`flex flex-wrap items-center justify-center ~/lg:~gap-y-8/10 ${slice.variation === 'compact' ? 'mt-0 gap-x-0' : `mt-20 gap-2 xl:px-14 ${slice.primary.logo_gap_compact ? 'sm:gap-x-8' : 'sm:gap-x-20'}`}`}
        >
          {slice.primary.logos.map((item, index) => (
            <li
              key={index}
              className="max-w-[139px] o-2 lg:max-w-[152px] xl:max-w-44"
            >
              <PrismicNextImage
                field={item.logo}
                fallbackAlt=""
                style={{ aspectRatio: item.aspect_ratio }}
                className="aspect-video object-contain"
              />
              {item.show_label && (
                <span className="block text-center ~/md:~text-arc-base/arc-lg">
                  {item.label}
                </span>
              )}
            </li>
          ))}
        </ul>
        {slice.primary.show_button && (
          <PrismicNextLink
            field={slice.primary.button_link}
            endIcon={<ArrowRightV2 />}
            variant="text"
            className="~mt-10/20"
          >
            {slice.primary.button_label}
          </PrismicNextLink>
        )}
      </Container>
    </section>
  );
};

export default FeaturedLogos;
